import { mutations } from "@/constants/state";

export default {
  [mutations.FREEDAYS_FETCH_OVERVIEW_MUTATION]: (state, payload) => {
    state.overview = payload;
  },
  [mutations.FREEDAYS_FETCH_USER_FREE_DAYS_MUTATION]: (state, payload) => {
    state.userFreeDays = payload;
  },
  [mutations.FREEDAYS_UPSERT_USER_FREE_DAYS_MUTATION]: (state, payload) => {
    state.userFreeDays = payload;
  },
};
