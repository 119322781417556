import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const getNewsList = () => {
  return axios.get(`${API_ENDPOINT()}/news`);
}

export const create = (payload) => {
  return axios.post(`${API_ENDPOINT()}/news`, payload);
};

export const update = (id, payload) => {
  return axios.put(`${API_ENDPOINT()}/news/${id}`,
    payload
  );
};

export const remove = (id) => {
  return axios.delete(`${API_ENDPOINT()}/news/${id}`);
};
