import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const getTimeSlotStats = () => {
  return axios.get(`${API_ENDPOINT()}/stats/timeslots`);
};

export const getTimeSlotStatDetailByUserId = (userId) => {
  return axios.get(`${API_ENDPOINT()}/stats/timeslots-detail/${userId}`);
};

export const getEmployeeStats = (from, until) => {
  return axios.get(`${API_ENDPOINT()}/stats/employees`, {
    params: { from, until }
  });
};
