import AgendaListResolver from "@/router/resolvers/agenda/list";
import AvailabilityReminderListResolver from "@/router/resolvers/availability-reminder/list";
import AvailableListResolver from "@/router/resolvers/available/list";
import FreeDayListResolver from "@/router/resolvers/free-day/list";
import FreeDayOverviewResolver from "@/router/resolvers/free-day/overview";
import HorecaLocationListResolver from "@/router/resolvers/horeca-locations/list";
import JobFilterListResolver from "@/router/resolvers/job-filters/list";
import MainFormResolver from "@/router/resolvers/main/form";
import MainListResolver from "@/router/resolvers/main/list";
import NewRoosterListResolver from "@/router/resolvers/new-rooster/list";
import SelectTemplateResolver from "@/router/resolvers/new-rooster/select-template";
import RoosterListResolver from "@/router/resolvers/rooster/list";
import ScenarioFormResolver from "@/router/resolvers/scenario/form";
import ScenarioListResolver from "@/router/resolvers/scenarios/list";
import SpecialJobFormResolver from "@/router/resolvers/special-job/form";
import SwapShiftsOverviewResolver from "@/router/resolvers/swap-shifts/overview";
import TemplateFormResolver from "@/router/resolvers/template/edit";
import TemplatesListResolver from "@/router/resolvers/template/list";
import TemplateSearchResolver from "@/router/resolvers/template/search";
import TimeoutListResolver from "@/router/resolvers/timeout/list";
import TimeOutOverviewResolver from "@/router/resolvers/timeout/overview";
import moment from "moment";
import Vue from "vue";
import VueRouter from "vue-router";
import multiguard from "vue-router-multiguard";
import RouteGuard from "./guards";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      redirect: "/main",
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/auth/Login.vue"),
    },
    {
      path: "/main",
      component: () => import("@/views/main/Index.vue"),
      children: [
        {
          path: "/",
          name: "Main List",
          beforeEnter: MainListResolver,
          component: () => import("@/views/main/List.vue"),
        },
        {
          path: "add/:week/:date",
          name: "Main Add",
          beforeEnter: MainFormResolver,
          component: () => import("@/views/main/Form.vue"),
        },
        {
          path: ":date/:id",
          name: "Main Edit",
          props: true,
          meta: { update: true },
          beforeEnter: MainFormResolver,
          component: () => import("@/views/main/Form.vue"),
        },
      ],
    },
    {
      path: "/agenda",
      name: "Agenda",
      beforeEnter: AgendaListResolver,
      component: () => import("@/views/agenda/Index.vue"),
    },
    {
      path: "/special-job/add",
      name: "Special Job Add",
      beforeEnter: SpecialJobFormResolver,
      component: () => import("@/views/special-job/Form.vue"),
    },
    {
      path: "/free-day",
      component: () => import("@/views/free-day/Index.vue"),
      children: [
        {
          path: "/",
          redirect: () => `/free-day/${moment().format("YYYY-MM")}`,
        },
        {
          path: ":yearMonth",
          beforeEnter: FreeDayListResolver,
          props: true,
          component: () => import("@/views/free-day/List.vue"),
        },
      ],
    },
    {
      path: "/available",
      component: () => import("@/views/available/Index.vue"),
      children: [
        {
          path: "/",
          redirect: () => `/available/${moment().format("YYYY-MM")}`,
        },
        {
          path: ":yearMonth",
          beforeEnter: AvailableListResolver,
          props: true,
          component: () => import("@/views/available/List.vue"),
        },
      ],
    },
    {
      path: "/free-day-overview",
      beforeEnter: FreeDayOverviewResolver,
      component: () => import("@/views/free-day/Overview.vue"),
    },
    {
      path: "/timeout",
      beforeEnter: TimeoutListResolver,
      component: () => import("@/views/timeout/Index.vue"),
    },
    {
      path: "/timeout-overview",
      beforeEnter: TimeOutOverviewResolver,
      component: () => import("@/views/timeout/Overview.vue"),
    },
    {
      path: "/rooster",
      beforeEnter: RoosterListResolver,
      component: () => import("@/views/rooster/Index.vue"),
    },
    {
      path: "/new-rooster",
      component: () => import("@/views/new-rooster/Index.vue"),
      children: [
        {
          path: "/",
          name: "New Rooster List",
          beforeEnter: NewRoosterListResolver,
          props: true,
          component: () => import("@/views/new-rooster/List.vue"),
        },
        {
          path: "select-template",
          name: "Select Template",
          beforeEnter: SelectTemplateResolver,
          component: () => import("@/views/new-rooster/SelectTemplate.vue"),
        },
      ],
    },
    {
      path: "/check-rooster",
      component: () => import("@/views/check-rooster/Index.vue"),
    },
    {
      path: "/note",
      component: () => import("@/views/note/Form.vue"),
    },
    {
      path: "/cancel-reason",
      component: () => import("@/views/cancel-reason/Form.vue"),
    },
    {
      path: "/templates",
      beforeEnter: TemplatesListResolver,
      component: () => import("@/views/templates/Index.vue"),
    },
    {
      path: "/comment/:date",
      component: () => import("@/views/comment/Form.vue"),
    },
    {
      path: "/mail-tool",
      component: () => import("@/views/mail-tool/Form.vue"),
    },
    {
      path: "/phone-list",
      component: () => import("@/views/phone-list/List.vue"),
    },
    {
      path: "/history",
      component: () => import("@/views/history/List.vue"),
    },
    {
      path: "/stats",
      component: () => import("@/views/stats/Index.vue"),
      children: [
        {
          path: "/",
          redirect: "timeslot",
        },
        {
          path: "timeslot",
          name: "Stats Timeslot",
          component: () => import("@/views/stats/TimeslotList.vue"),
        },
        {
          path: "timeslot/:userId",
          name: "Stats Timeslot Detail",
          component: () => import("@/views/stats/TimeslotDetail.vue"),
        },
        {
          path: "employee",
          name: "Stats Employee",
          component: () => import("@/views/stats/EmployeeList.vue"),
        },
      ],
    },
    {
      path: "/calloff-overview",
      component: () => import("@/views/calloff-overview/Index.vue"),
      children: [
        {
          path: "/",
          name: "Rooster",
          component: () => import("@/views/calloff-overview/List.vue"),
        },
        {
          path: ":userId",
          name: "Rooster",
          component: () => import("@/views/calloff-overview/SubList.vue"),
        },
      ],
    },
    {
      path: "/news",
      component: () => import("@/views/news/List.vue"),
    },
    {
      path: "/swap-shifts-overview",
      beforeEnter: SwapShiftsOverviewResolver,
      component: () => import("@/views/swap-shifts/Overview.vue"),
    },
    {
      path: "/availability-reminder",
      beforeEnter: AvailabilityReminderListResolver,
      component: () => import("@/views/availability-reminder/Index.vue"),
    },
    {
      path: "/manager/templates",
      component: () => import("@/views/manager/templates/Index.vue"),
      beforeEnter: multiguard([
        HorecaLocationListResolver,
        ScenarioListResolver,
      ]),
      children: [
        {
          path: "/",
          component: () => import("@/views/manager/templates/List.vue"),
          beforeEnter: TemplateSearchResolver,
        },
        {
          path: ":id/edit",
          component: () => import("@/views/manager/templates/Form.vue"),
          beforeEnter: TemplateFormResolver,
        },
      ],
    },
    {
      path: "/manager/start-times",
      component: () => import("@/views/manager/start-times/Index.vue"),
      beforeEnter: multiguard([HorecaLocationListResolver]),
    },
    {
      path: "/manager/scenarios",
      component: () => import("@/views/manager/scenarios/Index.vue"),
      children: [
        {
          path: "",
          name: "Scenarios Overview",
          component: () => import("@/views/manager/scenarios/Overview.vue"),
        },
        {
          path: "scenario-shifts",
          beforeEnter: ScenarioFormResolver,
          component: () => import("@/views/manager/scenarios/Form.vue"),
        },
        {
          path: "scenario-shifts/:id",
          beforeEnter: ScenarioFormResolver,
          component: () => import("@/views/manager/scenarios/Form.vue"),
        },
      ],
    },
    {
      path: "/manager/scheduler",
      component: () => import("@/views/manager/scheduler/Index.vue"),
      beforeEnter: multiguard([
        HorecaLocationListResolver,
        JobFilterListResolver,
        ScenarioListResolver,
      ]),
    },
  ],
});

router.beforeEach(RouteGuard);

export default router;
