import { actions, getters } from "@/constants/state";
import store from "@/store";

export default async (to, from, next) => {
  try {
    await store.dispatch(
      actions.SWAP_SHIFTS_FETCH_PENDING_ACTION,
      store.getters[getters.DOMAIN_LOCATION_ID_GETTER]
    );
    store.dispatch(actions.SWAP_SHIFTS_FETCH_CONFIRMED_ACTION, {
      showLastYear: 0,
    });
  } catch (error) {
    console.error("Swap shifts overview resolver", error);
  }

  next();
};
