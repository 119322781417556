import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const get = () => {
  return axios.get(`${API_ENDPOINT()}/cancel-reasons`);
};

export const create = (description) => {
  return axios.post(`${API_ENDPOINT()}/cancel-reasons`, {
    description
  });
};

export const remove = (id) => {
  return axios.delete(`${API_ENDPOINT()}/cancel-reasons/${id}`);
};
