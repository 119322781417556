import { mutations } from "@/constants/state";
import { filter, map } from "lodash";

export default {
  [mutations.AGENDAS_FETCH_MUTATION]: (state, payload) => {
    state.agendas = payload;
  },
  [mutations.AGENDAS_CREATE_MUTATION]: (state, payload) => {
    state.agendas = [...(state.agendas || []), payload];
  },
  [mutations.AGENDAS_UPDATE_MUTATION]: (state, payload) => {
    state.agendas = map(state.agendas, (agenda) =>
      agenda.id === payload.id ? payload : agenda
    );
  },
  [mutations.AGENDAS_REMOVE_MUTATION]: (state, id) => {
    state.agendas = filter(state.agendas, (agenda) => agenda.id !== id);
  },
};
