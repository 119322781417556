import { mutations } from "@/constants/state";
import { get, map, reject } from "lodash";

const sortScenariosByName = (name) => {
  return name.sort((a, b) => {
    const nameA = new Date(get(a, "name"));
    const nameB = new Date(get(b, "name"));
    return nameA - nameB;
  });
};

export default {
  [mutations.SCENARIOS_FETCH_OVERVIEW_MUTATION]: (state, payload) => {
    state.scenarios = sortScenariosByName(payload);
  },

  [mutations.SCENARIOS_FETCH_SCENARIO_MUTATION]: (state, payload) => {
    state.scenario = payload;
  },

  [mutations.SCENARIOS_CREATE_MUTATION]: (state, payload) => {
    state.scenarios = sortScenariosByName([...state.scenarios, payload]);
  },
  [mutations.SCENARIOS_UPDATE_MUTATION]: (state, payload) => {
    const data = map(state.scenarios, (scenarios) =>
      scenarios.id === payload.id ? { ...scenarios, ...payload } : scenarios
    );

    state.scenarios = sortScenariosByName(data);
  },
  [mutations.SCENARIOS_REMOVE_MUTATION]: (state, payload) => {
    const data = reject(state.scenarios, {
      id: payload,
    });

    state.scenarios = sortScenariosByName(data);
  },
};
