import { actions, mutations } from "@/constants/state";
import {
  bulkUpsert,
  getOverview,
  getUserFreeDays,
} from "@/services/freedays-service";

export default {
  [actions.FREEDAYS_FETCH_OVERVIEW_ACTION]: async (
    { commit },
    { from, until, jobFilterId }
  ) => {
    const { data } = await getOverview(from, until, jobFilterId);
    commit(mutations.FREEDAYS_FETCH_OVERVIEW_MUTATION, data);
  },
  [actions.FREEDAYS_FETCH_USER_FREE_DAYS_ACTION]: async (
    { commit },
    { userId, from, until }
  ) => {
    const { data } = await getUserFreeDays(userId, from, until);
    commit(mutations.FREEDAYS_FETCH_USER_FREE_DAYS_MUTATION, data);
  },
  [actions.FREEDAYS_UPSERT_USER_FREE_DAYS_ACTION]: async (
    { commit },
    payload
  ) => {
    const { data } = await bulkUpsert(payload);
    commit(mutations.FREEDAYS_UPSERT_USER_FREE_DAYS_MUTATION, data);
  },
};
