import { actions, getters } from "@/constants/state";
import store from "@/store";

export default async (to, from, next) => {
  try {
    await store.dispatch(
      actions.SPECIALJOB_FETCH_LATEST_ACTION,
      store.getters[getters.DOMAIN_LOCATION_ID_GETTER]
    );
  } catch (error) {
    console.error("DEBUG: Special Job - Form - Resolver", error);
  }

  next();
};
