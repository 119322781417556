import { actions, mutations } from "@/constants/state";
import {
  get,
  getPageData,
  remove,
  search,
  show,
} from "@/services/template-service";

export default {
  [actions.TEMPLATES_FETCH_ACTION]: async ({ commit }) => {
    const { data } = await get();
    commit(mutations.TEMPLATES_FETCH_MUTATION, data);
  },
  [actions.TEMPLATES_GET_EDIT_ACTION]: async ({ commit }, id) => {
    const { data } = await show(id);
    commit(mutations.TEMPLATES_GET_EDIT_MUTATION, data);
  },
  [actions.TEMPLATES_SEARCH_ACTION]: async ({ commit }, payload = {}) => {
    const { data } = await search(payload);
    commit(mutations.TEMPLATES_SEARCH_MUTATION, data);
  },
  [actions.TEMPLATES_FETCH_PAGE_DATA_ACTION]: async (
    { commit },
    { templateId, isCustom }
  ) => {
    const { data } = await getPageData(templateId, { isCustom });
    commit(mutations.TEMPLATES_FETCH_PAGE_DATA_MUTATION, data);
  },
  [actions.TEMPLATES_REMOVE_ACTION]: async ({ commit }, id) => {
    await remove(id);
    commit(mutations.TEMPLATES_REMOVE_MUTATION, id);
  },
};
