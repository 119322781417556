import { actions, mutations } from "@/constants/state";
import {
  get,
  getJobFilterJobs,
  getJobFilters,
  getUserJobs,
} from "@/services/job-service";

export default {
  [actions.JOBS_FETCH_ACTION]: async ({ commit }) => {
    const { data } = await get();
    commit(mutations.JOBS_FETCH_MUTATION, data);
  },
  [actions.JOBS_FETCH_USER_JOBS_ACTION]: async ({ commit }, { userId }) => {
    const { data } = await getUserJobs(userId);
    commit(mutations.JOBS_FETCH_USER_JOBS_MUTATION, data);
  },
  [actions.JOBS_FETCH_JOB_FILTERS_ACTION]: async ({ commit }) => {
    const { data } = await getJobFilters();
    commit(mutations.JOBS_FETCH_JOB_FILTERS_MUTATION, data);
  },
  [actions.JOBS_FETCH_JOB_FILTER_JOBS_ACTION]: async ({ commit }, payload) => {
    const { data } = await getJobFilterJobs(payload);
    commit(mutations.JOBS_FETCH_JOB_FILTER_JOBS_MUTATION, data);
  },
};
