import { actions } from "@/constants/state";
import store from "@/store";
import { get } from "lodash";

export default async (to, from, next) => {
  try {
    await store.dispatch(
      actions.TEMPLATES_GET_EDIT_ACTION,
      get(to, "params.id")
    );
    next();
  } catch (error) {
    console.error(actions.TEMPLATES_GET_EDIT_ACTION, error);
  }

  next();
};
