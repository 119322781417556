import { mutations } from "@/constants/state";

export default {
  [mutations.INPUTNOTE_FETCH_MUTATION]: (state, payload) => {
    state.inputNotes = payload;
  },
  [mutations.INPUTNOTE_UPSERT_MUTATION]: (state, text) => {
    state.inputNotes = {
      ...state.inputNotes,
      text
    };
  },
};
