import { getDomainWithScheme, toFormData } from "@/utils/misc";
import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const login = (email, password, locationId) => {
  return axios.post(
    `${API_ENDPOINT()}/auth/login`,
    toFormData({
      email,
      password,
      url: getDomainWithScheme(),
      horeca_locationID: locationId
    })
  );
};

export const rehydrateToken = () => {
  return axios.post(`${API_ENDPOINT()}/auth/rehydrate`);
};
