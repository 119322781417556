import { mutations } from "@/constants/state";
import { map, reject } from "lodash";

export default {
  [mutations.AVAILABLES_FETCH_USER_AVAILABLES_MUTATION]: (state, payload) => {
    state.userAvailables = payload;
  },
  [mutations.AVAILABLES_CREATE_USER_AVAILABLE_MUTATION]: (state, payload) => {
    state.userAvailables = [
      ...state.userAvailables,
      payload,
    ];
  },
  [mutations.AVAILABLES_UPDATE_USER_AVAILABLE_MUTATION]: (state, payload) => {
    state.userAvailables = map(state.userAvailables,
      (available) => available.id === payload.id ? payload : available)
  },
  [mutations.AVAILABLES_REMOVE_USER_AVAILABLE_MUTATION]: (state, id) => {
    state.userAvailables = reject(state.userAvailables, { id })
  },
};
