import { actions, mutations } from "@/constants/state";
import { get, create, update } from "@/services/comment-service";

export default {
  [actions.COMMENTS_FETCH_ACTION]: async (
    { commit },
    { from, until }
  ) => {
    const { data } = await get(from, until);
    commit(mutations.COMMENTS_FETCH_MUTATION, data);
  },
  [actions.COMMENTS_CREATE_ACTION]: async (
    { commit },
    { date, text, textEmployees }
  ) => {
    const { data } = await create(date, text, textEmployees);
    commit(mutations.COMMENTS_CREATE_MUTATION, data);
  },
  [actions.COMMENTS_UPDATE_ACTION]: async (
    { commit },
    { id, text, textEmployees }
  ) => {
    const { data } = await update(id, text, textEmployees);
    commit(mutations.COMMENTS_UPDATE_MUTATION, data);
  },
};
