import { mutations } from "@/constants/state";

export default {
  [mutations.TEMPLATE_SCHEDULES_FETCH_EVENTS_MUTATION]: (state, payload) => {
    state.templateSchedules = payload;
  },
  [mutations.TEMPLATE_SCHEDULES_UPSERT_MUTATION]: (state, payload) => {
    state.templateSchedules = [...(state.templateSchedules || []), payload];
  },
  [mutations.TEMPLATE_SCHEDULES_REMOVE_MUTATION]: (state, payload) => {
    state.templateSchedules = [...(state.templateSchedules || []), payload];
  },
};
