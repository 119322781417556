import { actions, mutations } from "@/constants/state";
import { create, latest } from "@/services/specialjob-service";

export default {
  [actions.SPECIALJOB_FETCH_LATEST_ACTION]: async ({ commit }) => {
    const { data } = await latest();
    commit(mutations.SPECIALJOB_FETCH_LATEST_MUTATION, data);
  },
  [actions.SPECIALJOB_CREATE_ACTION]: async (
    { commit },
    { locationId, userId, body }
  ) => {
    const { data } = await create(locationId, userId, body);
    commit(mutations.SPECIALJOB_FETCH_LATEST_MUTATION, data);
  },
};
