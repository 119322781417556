import { actions, mutations } from "@/constants/state";
import { get } from "@/services/hrnotification-service";

export default {
  [actions.HRNOTIFICATIONS_FETCH_ACTION]: async (
    { commit },
    { from, until }
  ) => {
    const { data } = await get(from, until);
    commit(mutations.HRNOTIFICATIONS_FETCH_MUTATION, data);
  },
};
