import menus from "@/constants/menu";
import { adminTypes } from "@/constants/misc";
import { getWeekDatesFromDate } from "@/utils/misc";
import moment from "@/utils/moment-utc";
import _ from "lodash";

export const isAdminOrSuperUser = (user) => {
  return (
    _.get(user, "super_user") ||
    _.some(adminTypes, (type) => type === _.get(user, "type.NAME"))
  );
};

export const getEnabledMenus = (user, all = false) => {
  const isFullTime = _.get(user, "user.always");
  let userMenus = [];

  if (_.get(user, "super_user")) {
    userMenus = menus.SUPERUSER;
  } else if (isAdminOrSuperUser(user)) {
    userMenus = isFullTime ? menus.ADMIN_FULLTIME : menus.ADMIN_PARTTIME;
  } else {
    userMenus = isFullTime ? menus.STAFF_FULLTIME : menus.STAFF_PARTTIME;
  }

  if (!all) {
    return _.filter(userMenus, { nav: true });
  }

  return userMenus;
};

export const getGroupedHolidays = (holidays, startOfWeek) => {
  const dates = getWeekDatesFromDate(startOfWeek);

  return _.reduce(
    _.values(dates),
    (grouped, date) => {
      return {
        ...(grouped || {}),
        [date]: _.uniqBy(
          _.filter(holidays, (holiday) =>
            moment(date).isBetween(holiday.from, holiday.until, null, "[]")
          ),
          (filteredHoliday) => filteredHoliday.user.id
        ),
      };
    },
    null
  );
};
