import { actions, getters } from "@/constants/state";
import { checkIfPublished } from "@/services/published-schedule-service";
import { getByDateRange } from "@/services/template-schedule-service";
import store from "@/store";
import { getEndWeekDuration } from "@/utils/misc";
import moment from "@/utils/moment-utc";
import { get, isEmpty } from "lodash";

export default async (to, from, next) => {
  try {
    const mainFilter = store.getters[getters.SESSION_MAIN_FILTER_GETTER];

    await store.dispatch(actions.SCHEDULEDT_FETCH_ACTION, {
      from: mainFilter.week,
      until: moment(mainFilter.week).add(6, "d").format("YYYY-MM-DD"),
      jobFilterId: mainFilter.jobFilterId,
    });

    const { data } = await getByDateRange({
      from: mainFilter.week,
      until: getEndWeekDuration(mainFilter.week),
      jobFilterId: mainFilter.jobFilterId,
    });

    const isPublished = await checkIfPublished({
      start: mainFilter.week,
      jobFilterId: mainFilter.jobFilterId,
    });

    if (
      (!isEmpty(data) && !get(isPublished, "data")) ||
      get(isPublished, "data")
    ) {
      return next("/new-rooster");
    }

    store.dispatch(actions.JOBS_FETCH_JOB_FILTERS_ACTION);
    store.dispatch(actions.INPUTNOTE_FETCH_ACTION);
    store.dispatch(actions.JOBS_FETCH_ACTION);
    const now = moment();
    const startWeek = now.isAfter(mainFilter.week)
      ? now.format("YYYY-MM-DD")
      : moment(mainFilter.week).format("YYYY-MM-DD");
    store.dispatch(actions.HOLIDAYS_FETCH_APPROVED_ACTION, {
      from: startWeek,
      until: getEndWeekDuration(startWeek),
    });

    await store.dispatch(actions.TEMPLATES_FETCH_ACTION);
    next();
  } catch (error) {
    console.error("DEBUG: New Rooster - Select Template - Resolver", error);
  }
};
