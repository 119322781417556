import { mutations } from "@/constants/state";

export default {
  [mutations.STATS_FETCH_TIMESLOTS_MUTATION]: (state, payload) => {
    state.timeslots = payload;
  },
  [mutations.STATS_FETCH_EMPLOYEES_MUTATION]: (state, payload) => {
    state.employees = payload;
  }
}
