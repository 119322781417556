import { getters } from "@/constants/state";
import { isAdminOrSuperUser } from "@/utils/user";

export default {
  [getters.AUTH_TOKEN_GETTER]: (state) => state.token,
  [getters.AUTH_USER_GETTER]: (state) => state.user,
  [getters.AUTH_USER_ID_GETTER]: (state) => state.user.user.id,
  [getters.AUTH_IS_FULLTIMER_GETTER]: (state) => state.user.user.always,
  [getters.AUTH_AUTHENTICATED_GETTER]: (state) => !!state.user && !!state.token,
  [getters.AUTH_IS_ADMIN_OR_SUPERUSER_GETTER]: (state) => isAdminOrSuperUser(state.user),
};
