import { actions, mutations } from "@/constants/state";
import { getTemplatesByLocation } from "@/services/template-location-service";

export default {
  [actions.TEMPLATE_LOCATIONS_FETCH_TEMPLATES_ACTION]: async (
    { commit },
    { horecaLocationId }
  ) => {
    const { data } = await getTemplatesByLocation(horecaLocationId);
    commit(mutations.TEMPLATE_LOCATIONS_FETCH_TEMPLATES_MUTATION, data);
  },
};
