import { actions, mutations } from "@/constants/state";
import {
  getPendingSwapShifts,
  getConfirmedSwapShifts,
  updateAction
} from "@/services/swap-shifts-service";

export default {
  [actions.SWAP_SHIFTS_FETCH_PENDING_ACTION]: async (
    { commit },
  ) => {
    const { data } = await getPendingSwapShifts();
    commit(mutations.SWAP_SHIFTS_FETCH_PENDING_MUTATION, data);
  },
  [actions.SWAP_SHIFTS_FETCH_CONFIRMED_ACTION]: async ({ commit }, payload) => {
    const { data } = await getConfirmedSwapShifts(payload);
    commit(mutations.SWAP_SHIFTS_FETCH_CONFIRMED_MUTATION, data);
  },
  [actions.SWAP_SHIFTS_UPDATE_ACTION]: async (
    { commit },
    { id, action }
  ) => {
    const { data } = await updateAction(id, action);
    commit(mutations.SWAP_SHIFTS_REMOVE_SWAP_SHIFT_MUTATION, data);
  },
};
