import { actions } from "@/constants/state";
import store from "@/store";

export default async (to, from, next) => {
  try {
    await store.dispatch(actions.SCENARIOS_FETCH_OVERVIEW_ACTION);
  } catch (error) {
    console.error(actions.SCENARIOS_FETCH_OVERVIEW_ACTION, error);
  }

  next();
};
