import { API_ENDPOINT } from "@/utils/misc";
import axios from "axios";

export const getEmployeesList = (payload) => {
  return axios.get(`${API_ENDPOINT()}/users/availability-reminder`, {
    params: payload,
  });
};

export const sendSmsTextMessage = (payload) => {
  return axios.post(`${API_ENDPOINT()}/users/send-sms-text`, payload);
};
