import { mutations } from "@/constants/state";
import { cloneDeep, findIndex, orderBy } from "lodash";

export default {
  [mutations.SET_START_TIMES_MUTATIONS]: (state, data) => {
    state.startTimes = data;
  },

  [mutations.ADD_START_TIMES_MUTATIONS]: (state, data) => {
    const clonedStartTimes = cloneDeep(state.startTimes);
    clonedStartTimes.push(data);
    state.startTimes = orderBy(clonedStartTimes, ["start_time"]);
  },

  [mutations.DELETE_START_TIMES_MUTATIONS]: (state, id) => {
    const clonedStartTimes = cloneDeep(state.startTimes);
    const index = findIndex(clonedStartTimes, { id: id });
    if (index >= 0) {
      clonedStartTimes.splice(index, 1);
    }
    state.startTimes = clonedStartTimes;
  },
};
