import { actions, mutations } from "@/constants/state";
import { getDateScenarioShifts } from "@/services/scenario-shift-service";

export default {
  [actions.SCENARIO_SHIFTS_FETCH_DATE_SCENARIO_SHIFTS_ACTION]: async (
    { commit },
    { date, userJobFilter }
  ) => {
    const { data } = await getDateScenarioShifts(date, userJobFilter);
    commit(mutations.SCENARIO_SHIFTS_FETCH_DATE_SCENARIO_SHIFTS_MUTATION, data);
  },
};
