import { get } from "lodash";
import moment from "moment";

export default async (to, from, next) => {
  try {
    let yearMonth = moment(get(to, "params.yearMonth"), "YYYY-MM");

    if (!yearMonth.isValid()) {
      next(`/free-day/${moment().format("YYYY-MM")}`);
      return;
    }

    to.params.yearMonth = yearMonth.format("YYYY-MM");
    console.log("DEBUG: Resolver yearMonth", to);
  } catch (error) {
    console.error("DEBUG: Freeday - List - Resolver", error);
  }

  next();
};
