import { mutations } from "@/constants/state";
import { map } from 'lodash';

export default {
  [mutations.COMMENTS_FETCH_MUTATION]: (state, payload) => {
    state.comments = payload;
  },
  [mutations.COMMENTS_CREATE_MUTATION]: (state, payload) => {
    state.comments = [
      ...state.comments,
      payload
    ];
  },
  [mutations.COMMENTS_UPDATE_MUTATION]: (state, payload) => {
    state.comments = map(state.comments,
      (comment)=> comment.id === payload.id ? payload : comment);
  },
};
