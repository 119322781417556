import { mutations } from "@/constants/state";
import { reject } from "lodash";

export default {
  [mutations.TEMPLATES_FETCH_MUTATION]: (state, payload) => {
    state.templates = payload;
  },
  [mutations.TEMPLATES_GET_EDIT_MUTATION]: (state, payload) => {
    state.editTemplate = payload;
  },
  [mutations.TEMPLATES_SEARCH_MUTATION]: (state, payload) => {
    state.searchedTemplates = payload;
  },
  [mutations.TEMPLATES_FETCH_PAGE_DATA_MUTATION]: (
    state,
    { dayShiftCount, templateHours }
  ) => {
    state.templatesPageData = templateHours;
    state.templatesDayShiftCount = dayShiftCount;
  },
  [mutations.TEMPLATES_REMOVE_MUTATION]: (state, id) => {
    state.templatesPageData = reject(state.templatesPageData, { id });
    state.searchedTemplates = reject(state.searchedTemplates, { id });
  },
};
