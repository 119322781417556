import { API_ENDPOINT } from "@/utils/misc";
import axios from "axios";

export const getOverview = (from, until, jobFilterId) => {
  return axios.get(`${API_ENDPOINT()}/free-days/overview`, {
    params: { from, until, jobFilterId },
  });
};

export const getUserFreeDays = (userId, from, until) => {
  return axios.get(`${API_ENDPOINT()}/free-days/getby/user/${userId}`, {
    params: { from, until, userId },
  });
};

export const getTodaysUsersFreeDay = (date) => {
  return axios.get(`${API_ENDPOINT()}/free-days/get-users-free-day-by-date`, {
    params: { date },
  });
};

export const bulkUpsert = (payload) => {
  return axios.post(`${API_ENDPOINT()}/free-days/bulk-upsert`, payload);
};
