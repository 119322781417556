import { actions, mutations } from "@/constants/state";
import { getEmployeesList } from "@/services/availability-reminder-service";

export default {
  [actions.AVAILABILITY_REMINDER_FETCH_EMPLOYEES_ACTION]: async (
    { commit },
    payload
  ) => {
    const { data } = await getEmployeesList(payload);
    commit(mutations.AVAILABILITY_REMINDER_FETCH_EMPLOYEES_MUTATION, data);
  },
};
