import { actions } from "@/constants/state";
import store from "@/store";
import { get } from "lodash";

export default async (to, from, next) => {
  try {
    if (get(to, "params.id")) {
      await store.dispatch(
        actions.SCENARIOS_FETCH_SCENARIO_ACTION,
        get(to, "params.id")
      );
    }
  } catch (error) {
    console.error("DEBUG: Scenario - Form - Resolver", error);
  }

  next();
};
