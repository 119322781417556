import { get } from "lodash";
import moment from "moment";

export default async (to, from, next) => {
  try {
    let yearMonth = moment(get(to, "params.yearMonth"), "YYYY-MM");

    if (!yearMonth.isValid()) {
      next(`/available/${moment().format("YYYY-MM")}`);
      return;
    }

    to.params.yearMonth = yearMonth.format("YYYY-MM");
  } catch (error) {
    console.error("DEBUG: Available - List - Resolver", error);
  }

  next();
};
