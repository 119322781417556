import { API_ENDPOINT } from "@/utils/misc";
import axios from "axios";

export const get = () => {
  return axios.get(`${API_ENDPOINT()}/templates`);
};

export const show = (id) => {
  return axios.get(`${API_ENDPOINT()}/templates/${id}`);
};

export const search = (payload) => {
  return axios.get(`${API_ENDPOINT()}/templates/search`, {
    params: payload,
  });
};

export const create = (payload) => {
  return axios.post(`${API_ENDPOINT()}/templates`, payload);
};

export const copy = (payload) => {
  return axios.post(`${API_ENDPOINT()}/templates/copy`, payload);
};

export const update = (payload) => {
  return axios.post(
    `${API_ENDPOINT()}/templates/${payload.id}?_method=PUT`,
    payload
  );
};

export const getPageData = (templateId, payload) => {
  return axios.get(`${API_ENDPOINT()}/templates/${templateId}/page-data`, {
    params: payload,
  });
};

export const remove = (id) => {
  return axios.delete(`${API_ENDPOINT()}/templates/${id}`);
};
