import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const get = (from, to) => {
  return axios.get(`${API_ENDPOINT()}/comments`, {
    params: { from, to },
  });
};

export const getCommentByDate = (date) => {
  return axios.get(`${API_ENDPOINT()}/comments/getby/date/${date}`);
}

export const create = (date, text, textEmployees) => {
  return axios.post(`${API_ENDPOINT()}/comments`, {
    date,
    text,
    text_employees: textEmployees
  });
};

export const update = (id, text, textEmployees) => {
  return axios.put(`${API_ENDPOINT()}/comments/${id}`, {
    text,
    text_employees: textEmployees
  });
};
