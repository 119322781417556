import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const getDateScenarioShifts = (date, userJobFilter) => {
  return axios.get(
    `${API_ENDPOINT()}/scenario-shifts/get-date-scenario-shifts`,
    {
      params: { date, userJobFilter },
    }
  );
};
