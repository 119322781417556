import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const latest = () => {
  return axios.get(`${API_ENDPOINT()}/special-jobs/latest`);
};

export const create = (locationId, userId, body) => {
  return axios.post(`${API_ENDPOINT()}/special-jobs`, {
    horeca_location_id: locationId,
    user_id: userId,
    body,
  });
};
