import { API_ENDPOINT } from "@/utils/misc";
import axios from "axios";

export const checkDomain = (domain) => {
  return axios.get(`${API_ENDPOINT()}/horeca_locations/check?domain=${domain}`);
};

export const updateSmsTextConfig = (id, payload) => {
  return axios.post(
    `${API_ENDPOINT()}/horeca-locations/${id}/sms-text-config`,
    payload
  );
};
