import { mutations } from "@/constants/state";
import { reject, map, get } from "lodash";

const sortNewsByDate = (news) => {
  return news.sort((a, b) => {
    const dateA = new Date(get(a, 'date'));
    const dateB = new Date(get(b, 'date'));
    return dateA - dateB;
  });
};

export default {
  [mutations.NEWS_FETCH_MUTATION]: (state, payload) => {
    state.news = sortNewsByDate(payload);
  },
  [mutations.NEWS_CREATE_MUTATION]: (state, payload) => {
    state.news = sortNewsByDate([...state.news, payload]);
  },
  [mutations.NEWS_UPDATE_MUTATION]: (state, payload) => {
    const data = map(state.news, (news) =>
      news.id === payload.id ? { ...news, ...payload } : news
    );

    state.news = sortNewsByDate(data);
  },
  [mutations.NEWS_REMOVE_MUTATION]: (state, payload) => {
    const data = reject(state.news, {
      id: payload,
    });

    state.news = sortNewsByDate(data);
  },
};
