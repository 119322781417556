import { actions, mutations } from "@/constants/state";
import { getAll, store, destroy } from "@/services/start-times-service";

export default {
  [actions.SET_START_TIMES_ACTION]: async ({ commit }) => {
    const { data } = await getAll();
    commit(mutations.SET_START_TIMES_MUTATIONS, data);
  },

  [actions.ADD_START_TIMES_ACTION]: async ({ commit }, payload) => {
    const { data } = await store(payload);
    commit(mutations.ADD_START_TIMES_MUTATIONS, data);
  },

  [actions.DELETE_START_TIMES_ACTION]: async ({ commit }, id) => {
    await destroy(id);
    commit(mutations.DELETE_START_TIMES_MUTATIONS, id);
  },
};
