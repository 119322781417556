import { actions, mutations } from "@/constants/state";
import { getTimeSlotStats, getEmployeeStats } from "@/services/stat-service";

export default {
  [actions.STATS_FETCH_TIMESLOTS_ACTION]: async ({ commit }) => {
    const { data } = await getTimeSlotStats();
    commit(mutations.STATS_FETCH_TIMESLOTS_MUTATION, data);
  },
  [actions.STATS_FETCH_EMPLOYEES_ACTION]: async ({ commit }, { from, until }) => {
    console.log('DEBUG: action', from, until)
    const { data } = await getEmployeeStats(from, until);
    commit(mutations.STATS_FETCH_EMPLOYEES_MUTATION, data);
  },
};
