import { mutations } from "@/constants/state";
import { reject } from "lodash";

export default {
  [mutations.SWAP_SHIFTS_FETCH_PENDING_MUTATION]: (state, payload) => {
    state.unapprovedSwapShifts = payload;
  },
  [mutations.SWAP_SHIFTS_FETCH_CONFIRMED_MUTATION]: (state, payload) => {
    state.confirmedSwapShifts = payload;
  },
  [mutations.SWAP_SHIFTS_REMOVE_SWAP_SHIFT_MUTATION]: (state, payload) => {
    state.unapprovedSwapShifts = reject(state.unapprovedSwapShifts, {
      id: payload,
    });
  },
};
