import { actions, getters } from "@/constants/state";
import store from "@/store";
import { getEnabledMenus } from "@/utils/user";
import { get, some } from "lodash";

export default async (to, from, next) => {
  if (!store.getters[getters.DOMAIN_GETTER]) {
    try {
      await store.dispatch(actions.DOMAIN_FETCH_ACTION);
      const locationName = store.getters[getters.DOMAIN_LOCATION_NAME_GETTER];
      document.title = `Rooster | ${locationName}`;
    } catch (error) {
      console.error("DEBUG: routeGuard", error);
    }
  }

  if (to.path === "/login") {
    return next();
  }

  const authenticated = store.getters[getters.AUTH_AUTHENTICATED_GETTER];
  if (!authenticated) {
    return store.dispatch(actions.AUTH_LOGOUT_ACTION);
  }

  const menus = getEnabledMenus(store.getters[getters.AUTH_USER_GETTER], true);
  const path = get(to.path.split('/'), 1);
  if (!some(menus, (menu) => menu.url.includes(`/${path}`))) {
    return next('/main');
  }

  next();
};
