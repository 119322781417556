import i18n from "@/i18n";
import moment from "@/utils/moment-utc";
import _ from "lodash";

export const getDomainWithScheme = () => {
  const { origin, port } = window.location;
  return _.replace(origin, `:${port}`, "");
};

export const toFormData = (payload) => {
  const formData = new FormData();
  _.each(payload, (value, key) => formData.append(key, value));

  return formData;
};

export const getWeekOptions = (isAdminOrSuperUser) => {
  const startOfWeek = moment().startOf("week").add(1, "d");
  const startDate = moment("2018-01-01", "YYYY-MM-DD");
  const pastWeekCount = Math.floor(
    moment.duration(startOfWeek.diff(startDate)).asWeeks()
  );
  const futureWeekCount = Math.floor(
    moment
      .duration(startOfWeek.clone().add(2, "years").diff(startOfWeek))
      .asWeeks()
  );
  const adminWeekCount = pastWeekCount + futureWeekCount;
  const partTimeWeekCount = 34;
  const maxWeek = isAdminOrSuperUser ? adminWeekCount : partTimeWeekCount;
  const options = [];

  for (let i = 0; i <= maxWeek; i++) {
    const date = isAdminOrSuperUser
      ? startOfWeek
          .clone()
          .add(i * 7, "d")
          .subtract(startOfWeek.diff(startDate, "d"), "d")
      : startOfWeek
          .clone()
          .add(i * 7, "d")
          .subtract(70, "d");
    options.push(date.format("YYYY-MM-DD"));
  }

  return options;
};

export const weekdaySorter = (weekdaysObject) => {
  const data = [];

  _.forEach(weekdaysObject, (value, key) => {
    data.push({ day: key, date: value });
  });

  const sorter = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  data.sort(function sortByDay(a, b) {
    let day1 = a.day.toLowerCase();
    let day2 = b.day.toLowerCase();
    return sorter[day1] - sorter[day2];
  });

  return data;
};

export const getWeekDatesFromDate = (date) => {
  const startOfWeek = moment(date);

  return {
    [i18n.t("monday")]: startOfWeek.format("YYYY-MM-DD"),
    [i18n.t("tuesday")]: startOfWeek.clone().add(1, "d").format("YYYY-MM-DD"),
    [i18n.t("wednesday")]: startOfWeek.clone().add(2, "d").format("YYYY-MM-DD"),
    [i18n.t("thursday")]: startOfWeek.clone().add(3, "d").format("YYYY-MM-DD"),
    [i18n.t("friday")]: startOfWeek.clone().add(4, "d").format("YYYY-MM-DD"),
    [i18n.t("saturday")]: startOfWeek.clone().add(5, "d").format("YYYY-MM-DD"),
    [i18n.t("sunday")]: startOfWeek.clone().add(6, "d").format("YYYY-MM-DD"),
  };
};

export const getMonthTranslation = (month) => {
  switch (+month) {
    case 1:
      return i18n.t("january");
    case 2:
      return i18n.t("february");
    case 3:
      return i18n.t("march");
    case 4:
      return i18n.t("april");
    case 5:
      return i18n.t("may");
    case 6:
      return i18n.t("june");
    case 7:
      return i18n.t("july");
    case 8:
      return i18n.t("august");
    case 9:
      return i18n.t("september");
    case 10:
      return i18n.t("october");
    case 11:
      return i18n.t("november");
    default:
      return i18n.t("december");
  }
};

export const getDayTranslation = (day) => {
  switch (day) {
    case 1:
      return i18n.t("monday");
    case 2:
      return i18n.t("tuesday");
    case 3:
      return i18n.t("wednesday");
    case 4:
      return i18n.t("thursday");
    case 5:
      return i18n.t("friday");
    case 6:
      return i18n.t("saturday");
    default:
      return i18n.t("sunday");
  }
};

export const getWeekDayTranslationShort = (day, initial = false) => {
  switch (day) {
    case i18n.t("monday"):
      return initial ? i18n.t("monday_initial") : i18n.t("monday_short");
    case i18n.t("tuesday"):
      return initial ? i18n.t("tuesday_initial") : i18n.t("tuesday_short");
    case i18n.t("wednesday"):
      return initial ? i18n.t("wednesday_initial") : i18n.t("wednesday_short");
    case i18n.t("thursday"):
      return initial ? i18n.t("thursday_initial") : i18n.t("thursday_short");
    case i18n.t("friday"):
      return initial ? i18n.t("friday_initial") : i18n.t("friday_short");
    case i18n.t("saturday"):
      return initial ? i18n.t("saturday_initial") : i18n.t("saturday_short");
    default:
      return initial ? i18n.t("sunday_initial") : i18n.t("sunday_short");
  }
};

export const getTimeSlotOptions = (includeEndTimeCallTime = true) => {
  const today = moment().startOf("day");
  const timeSlots = {
    start: {
      "04:45": "04:45",
      "05:00": "05:00",
      "05:15": "05:15",
      "05:30": "05:30",
      "05:45": "05:45",
      "06:00": "06:00",
      "06:15": "06:15",
      "06:30": "06:30",
      "06:45": "06:45",
    },
  };

  for (let hour = 7; hour <= 24; hour++) {
    for (let minute = 0; minute <= 30; minute += 30) {
      const time = today.clone().set({ hour, minute }).format("HH:mm");

      // Start
      if (hour <= 22) {
        _.set(timeSlots, `start.${time}`, time);
      }

      // End
      if (time == "07:00") {
        if (includeEndTimeCallTime) {
          _.set(timeSlots, `end.${time}`, i18n.t("call"));
        }

        continue;
      }

      _.set(timeSlots, `end.${time}`, time);
    }
  }

  _.set(timeSlots, "end.02:00", i18n.t("close"));
  _.set(timeSlots, "end.02:30", i18n.t("needed"));

  return timeSlots;
};

export const getEndWeekDuration = (week) =>
  moment(week).add(6, "d").format("YYYY-MM-DD");

export const getDaysOfWeek = (startDate) =>
  [0, 1, 2, 3, 4, 5, 6].map((day) =>
    moment(startDate).add(day, "d").format("YYYY-MM-DD")
  );

export const getTimeText = (time) => {
  if (time === "07:00") {
    return i18n.t("call");
  }

  if (time === "02:00") {
    return i18n.t("close");
  }

  if (time === "02:30") {
    return i18n.t("needed");
  }

  return time;
};

export const getEndTimeDay = (date, startTime, endTime) => {
  const endTimeMoment = moment(endTime, "HH:mm:ss");
  const defaultDate = moment(
    `${date} ${endTime}`,
    "YYYY-MM-DD HH:mm:ss"
  ).format("YYYY-MM-DD HH:mm:ss");

  if (moment(startTime, "HH:mm:ss") < endTimeMoment) {
    return defaultDate;
  }

  return endTimeMoment <= moment("07:00", "HH:mm:ss")
    ? moment(`${date} ${endTime}`, "YYYY-MM-DD HH:mm:ss")
        .add(1, "d")
        .format("YYYY-MM-DD HH:mm:ss")
    : defaultDate;
};

export const getScenarioShiftTimes = (from, until) => {
  from = moment(from, "HH:mm:ss").format("HH:mm");
  until = moment(until, "HH:mm:ss").format("HH:mm");

  if (from === "07:00" && until === "02:00") {
    return i18n.t("available_selected_hour_allday");
  }

  if (from === "17:00" && until === "02:00") {
    return i18n.t("available_selected_hour_evening");
  }

  from = from === "07:00" ? i18n.t("call") : from;
  until = until === "02:00" ? i18n.t("close") : until;

  return `${from} - ${until}`;
};

export const API_ENDPOINT = () => {
  const hostName = window.location.hostname;

  switch (hostName) {
    case "development.dev-roosterv2.eyestone.nl":
    case "localhost":
      return "http://development.dev-roosterv2.eyestone.nl/api";
    default:
      return `${window.location.origin}/api`;
  }
};

export const getOffset = (el) => {
  let x = 0;
  let y = 0;

  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    x += el.offsetLeft - el.scrollLeft;
    y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }

  return { top: y, left: x };
};
