import { actions, mutations } from "@/constants/state";
import {
  copyFromTemplate,
  create,
  get,
  publish,
  remove,
} from "@/services/scheduledt-service";

export default {
  [actions.SCHEDULEDT_FETCH_ACTION]: async (
    { commit },
    { from, until, jobFilterId }
  ) => {
    const { data } = await get(from, until, jobFilterId);
    commit(mutations.SCHEDULEDT_FETCH_MUTATION, data);
  },
  [actions.SCHEDULEDT_PUBLISH_ACTION]: async (
    action,
    { from, until, jobFilterId }
  ) => {
    await publish(from, until, jobFilterId);
  },
  [actions.SCHEDULEDT_COPY_FROM_TEMPLATE_ACTION]: async (
    action,
    { from, until, templateId, jobFilterId }
  ) => {
    await copyFromTemplate(from, until, templateId, jobFilterId);
  },
  [actions.SCHEDULEDT_CREATE_ACTION]: async (
    action,
    { from, until, jobId }
  ) => {
    await create(from, until, jobId);
  },
  [actions.SCHEDULEDT_REMOVE_ACTION]: async ({ commit }, id) => {
    await remove(id);
    commit(mutations.SCHEDULEDT_REMOVE_MUTATION, id);
  },
};
