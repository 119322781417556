import { mutations } from "@/constants/state";

export default {
  [mutations.AUTH_LOGIN_MUTATION]: (state, { user, token }) => {
    state.token = token;
    state.user = user;
  },
  [mutations.AUTH_LOGOUT_MUTATION]: (state) => {
    state.token = null;
    state.user = null;
  },
};
