import { API_ENDPOINT } from "@/utils/misc";
import axios from "axios";

export const getScenariosList = () => {
  return axios.get(`${API_ENDPOINT()}/scenarios`);
};

export const getScenarioById = (id) => {
  return axios.get(`${API_ENDPOINT()}/scenarios/${id}`);
};

export const create = (payload) => {
  return axios.post(`${API_ENDPOINT()}/scenarios`, payload);
};

export const update = (id, payload) => {
  return axios.put(`${API_ENDPOINT()}/scenarios/${id}`, payload);
};

export const copy = (payload) => {
  return axios.post(`${API_ENDPOINT()}/scenarios/copy`, payload);
};

export const remove = (id) => {
  return axios.delete(`${API_ENDPOINT()}/scenarios/${id}`);
};
