import { actions, getters } from "@/constants/state";
import store from "@/store";
import { getEndWeekDuration } from "@/utils/misc";

export default async (to, from, next) => {
  try {
    await store.dispatch(actions.JOBS_FETCH_JOB_FILTERS_ACTION);

    const mainFilter = store.getters[getters.SESSION_MAIN_FILTER_GETTER];

    await store.dispatch(actions.JOBS_FETCH_JOB_FILTER_JOBS_ACTION, {
      jobFilterId: mainFilter.jobFilterId,
      locationId: store.getters[getters.DOMAIN_LOCATION_ID_GETTER],
    });

    await store.dispatch(actions.AVAILABILITY_REMINDER_FETCH_EMPLOYEES_ACTION, {
      from: mainFilter.week,
      until: getEndWeekDuration(mainFilter.week),
      jobFilterId: mainFilter.jobFilterId,
      jobId: mainFilter.jobId,
    });
  } catch (error) {
    console.error("DEBUG: Availability Reminder - List - Resolver", error);
  }

  next();
};
