import { actions, mutations } from "@/constants/state";
import { getUserAvailables, create, update, remove } from "@/services/available-service";

export default {
  [actions.AVAILABLES_FETCH_USER_AVAILABLES_ACTION]: async ({ commit }, { userId, from, until }) => {
    const { data } = await getUserAvailables(userId, from, until);
    commit(mutations.AVAILABLES_FETCH_USER_AVAILABLES_MUTATION, data);
  },
  [actions.AVAILABLES_CREATE_USER_AVAILABLE_ACTION]: async ({ commit }, { userId, from, until }) => {
    const { data } = await create(userId, from, until);
    commit(mutations.AVAILABLES_CREATE_USER_AVAILABLE_MUTATION, data);
  },
  [actions.AVAILABLES_UPDATE_USER_AVAILABLE_ACTION]: async ({ commit }, { id, from, until }) => {
    const { data } = await update(id, from, until);
    commit(mutations.AVAILABLES_UPDATE_USER_AVAILABLE_MUTATION, data);
  },
  [actions.AVAILABLES_REMOVE_USER_AVAILABLE_ACTION]: async ({ commit }, id) => {
    await remove(id);
    commit(mutations.AVAILABLES_REMOVE_USER_AVAILABLE_MUTATION, id);
  },
};
