import { API_ENDPOINT } from "@/utils/misc";
import axios from "axios";

export const get = () => {
  return axios.get(`${API_ENDPOINT()}/jobs`);
};

export const getUserJobs = (userId) => {
  return axios.get(`${API_ENDPOINT()}/users/${userId}/jobs`);
};

export const getJobsByLocation = (locationId = null) => {
  return axios.get(`${API_ENDPOINT()}/jobs/getby/location`, {
    params: { locationId },
  });
};

export const getJobFilters = () => {
  return axios.get(`${API_ENDPOINT()}/jobs/filters`);
};

export const getJobFilterJobs = (payload) => {
  return axios.get(`${API_ENDPOINT()}/jobs/getby/filter`, {
    params: payload,
  });
};
