import { actions, mutations } from "@/constants/state";
import { get, upsert } from "@/services/inputnote-service";

export default {
  [actions.INPUTNOTE_FETCH_ACTION]: async (
    { commit },
  ) => {
    const { data } = await get();
    commit(mutations.INPUTNOTE_FETCH_MUTATION, data);
  },
  [actions.INPUTNOTE_UPSERT_ACTION]: async (
    { commit },
    text
  ) => {
    await upsert(text);
    commit(mutations.INPUTNOTE_UPSERT_MUTATION, text);
  },
};
