import { omit } from "lodash";
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import agendas from "./modules/agendas";
import auth from "./modules/auth";
import availabilityReminder from "./modules/availability-reminder";
import availables from "./modules/availables";
import comments from "./modules/comments";
import domain from "./modules/domain";
import freeDays from "./modules/free-days";
import fullSchedules from "./modules/full-schedules";
import holidays from "./modules/holidays";
import horecaLocations from "./modules/horeca-locations";
import hrnotifications from "./modules/hr-notifications";
import inputNotes from "./modules/input-notes";
import jobs from "./modules/jobs";
import news from "./modules/news";
import scenarioShifts from "./modules/scenario-shifts";
import scenarios from "./modules/scenarios";
import scheduleDt from "./modules/schedule-dt";
import session from "./modules/session";
import specialJob from "./modules/special-job";
import startTimes from "./modules/start_times";
import stats from "./modules/stats";
import swapShifts from "./modules/swap-shifts";
import templateLocations from "./modules/template-locations";
import templateSchedules from "./modules/template-schedules";
import templates from "./modules/templates";
import users from "./modules/users";

Vue.use(Vuex);

const persistState = new VuexPersistence({
  storage: window.localStorage,
  key: "rooster-v2",
  reducer: (state) => ({
    auth: omit(state.auth, ["isAdminOrSuperUser"]),
    session: state.session,
  }),
});

export default new Vuex.Store({
  modules: {
    auth,
    agendas,
    comments,
    hrnotifications,
    specialJob,
    fullSchedules,
    domain,
    freeDays,
    holidays,
    jobs,
    users,
    session,
    templates,
    inputNotes,
    scheduleDt,
    stats,
    availables,
    news,
    swapShifts,
    scenarios,
    scenarioShifts,
    startTimes,
    horecaLocations,
    templateSchedules,
    templateLocations,
    availabilityReminder,
  },
  plugins: [persistState.plugin],
});
