import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const get = () => {
  return axios.get(`${API_ENDPOINT()}/agendas`);
};

export const create = (payload) => {
  return axios.post(`${API_ENDPOINT()}/agendas`, payload);
};

export const update = (id, payload) => {
  return axios.post(
    `${API_ENDPOINT()}/agendas/${id}?_method=PUT`,
    payload
  );
};

export const remove = (id) => {
  return axios.delete(`${API_ENDPOINT()}/agendas/${id}`);
};
