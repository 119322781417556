import { actions, mutations } from "@/constants/state";
import router from "@/router";
import { login, rehydrateToken } from "@/services/auth-service";
import moment from "moment";

export default {
  [actions.AUTH_LOGIN_ACTION]: async (
    { commit },
    { email, password, locationId }
  ) => {
    const { data } = await login(email, password, locationId);
    if (data.staffDomain !== null) {
      window.location.href = `https://${data.staffDomain}/login?token=${data.token}`;
      return false;
    }

    commit(mutations.AUTH_LOGIN_MUTATION, data);
    return true;
  },
  [actions.AUTH_REHYDRATE_ACTION]: async ({ commit }) => {
    const { data } = await rehydrateToken();
    commit(mutations.AUTH_LOGIN_MUTATION, data);
    localStorage.setItem("last-rehydrated", moment.now());
  },
  [actions.AUTH_LOGOUT_ACTION]: ({ commit }) => {
    commit(mutations.AUTH_LOGOUT_MUTATION);
    router.push("/login");
  },
};
