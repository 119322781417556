import { actions, mutations } from "@/constants/state";
import {
  create,
  getApprovedHolidays,
  getConfirmedHolidays,
  getUnapprovedHolidays,
  getUserHolidays,
  remove,
  updateApproved,
} from "@/services/holiday-service";

export default {
  [actions.HOLIDAYS_FETCH_APPROVED_ACTION]: async (
    { commit },
    { from, until, jobFilterId }
  ) => {
    const { data } = await getApprovedHolidays(from, until, jobFilterId);
    commit(mutations.HOLIDAYS_FETCH_APPROVED_MUTATION, data);
  },
  [actions.HOLIDAYS_FETCH_UNAPPROVED_ACTION]: async ({ commit }) => {
    const { data } = await getUnapprovedHolidays();
    commit(mutations.HOLIDAYS_FETCH_UNAPPROVED_MUTATION, data);
  },
  [actions.HOLIDAYS_FETCH_CONFIRMED_ACTION]: async ({ commit }, payload) => {
    const { data } = await getConfirmedHolidays(payload);
    commit(mutations.HOLIDAYS_FETCH_CONFIRMED_MUTATION, data);
  },
  [actions.HOLIDAYS_FETCH_USER_HOLIDAYS_ACTION]: async (
    { commit },
    { userId }
  ) => {
    const { data } = await getUserHolidays(userId);
    commit(mutations.HOLIDAYS_FETCH_USER_HOLIDAYS_MUTATION, data);
  },
  [actions.HOLIDAYS_CREATE_USER_HOLIDAY_ACTION]: async (
    { commit },
    payload
  ) => {
    const { data } = await create(payload);
    commit(mutations.HOLIDAYS_CREATE_USER_HOLIDAY_MUTATION, data);
  },
  [actions.HOLIDAYS_UPDATE_APPROVED_ACTION]: async (
    { commit },
    { id, approved }
  ) => {
    const { data } = await updateApproved(id, approved);
    commit(mutations.HOLIDAYS_REMOVE_UNAPPROVED_HOLIDAY_MUTATION, data);
  },
  [actions.HOLIDAYS_REMOVE_USER_HOLIDAY_ACTION]: async (
    { commit },
    { id, sendMail }
  ) => {
    await remove(id, sendMail);
    commit(mutations.HOLIDAYS_REMOVE_UNAPPROVED_HOLIDAY_MUTATION, id);
    commit(mutations.HOLIDAYS_REMOVE_CONFIRMED_HOLIDAY_MUTATION, id);
    commit(mutations.HOLIDAYS_REMOVE_USER_HOLIDAY_MUTATION, id);
  },
};
