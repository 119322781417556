import { actions, mutations } from "@/constants/state";
import {
  create,
  getScenarioById,
  getScenariosList,
  remove,
  update,
} from "@/services/scenario-service";

export default {
  [actions.SCENARIOS_FETCH_OVERVIEW_ACTION]: async ({ commit }) => {
    const { data } = await getScenariosList();
    commit(mutations.SCENARIOS_FETCH_OVERVIEW_MUTATION, data);
  },

  [actions.SCENARIOS_FETCH_SCENARIO_ACTION]: async ({ commit }, id) => {
    const { data } = await getScenarioById(id);
    commit(mutations.SCENARIOS_FETCH_SCENARIO_MUTATION, data);
  },

  [actions.SCENARIOS_CREATE_ACTION]: async ({ commit, dispatch }, payload) => {
    const { data } = await create(payload);
    commit(mutations.SCENARIOS_CREATE_MUTATION, data);

    await dispatch(actions.SCENARIOS_FETCH_SCENARIO_ACTION, data.id);

    return data;
  },

  [actions.SCENARIOS_UPDATE_ACTION]: async (
    { commit, dispatch },
    { id, payload }
  ) => {
    const { data } = await update(id, payload);
    commit(mutations.SCENARIOS_UPDATE_MUTATION, data);

    await dispatch(actions.SCENARIOS_FETCH_SCENARIO_ACTION, id);
  },

  [actions.SCENARIOS_REMOVE_ACTION]: async ({ commit }, id) => {
    await remove(id);
    commit(mutations.SCENARIOS_REMOVE_MUTATION, id);
  },
};
