import { mutations } from "@/constants/state";

export default {
  [mutations.SCENARIO_SHIFTS_FETCH_DATE_SCENARIO_SHIFTS_MUTATION]: (
    state,
    payload
  ) => {
    state.dateScenarioShifts = payload;
  },
};
