import { actions, mutations } from "@/constants/state";
import {
  getEvents,
  upsert,
  remove,
} from "@/services/template-schedule-service";

export default {
  [actions.TEMPLATE_SCHEDULES_FETCH_EVENTS_ACTION]: async (
    { commit },
    { horecaLocationId, jobFilterId, start, end }
  ) => {
    const { data } = await getEvents(horecaLocationId, jobFilterId, start, end);
    commit(mutations.TEMPLATE_SCHEDULES_FETCH_EVENTS_MUTATION, data);
  },
  [actions.TEMPLATE_SCHEDULES_UPSERT_ACTION]: async ({ commit }, payload) => {
    const { data } = await upsert(payload);
    commit(mutations.TEMPLATE_SCHEDULES_UPSERT_MUTATION, data);
    return data;
  },
  [actions.TEMPLATE_SCHEDULES_REMOVE_ACTION]: async ({ commit }, payload) => {
    const { data } = await remove(payload);
    commit(mutations.TEMPLATE_SCHEDULES_REMOVE_MUTATION, data);
  },
};
