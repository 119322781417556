import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const getOptions = (date) => {
  return axios.get(`${API_ENDPOINT()}/users/options`, {
    params: { date }
  });
};

export const getEnabled = () => {
  return axios.get(`${API_ENDPOINT()}/users/enabled`);
};

export const getKitchenUsers = (date) => {
  return axios.get(`${API_ENDPOINT()}/users/kitchen-check`, {
    params: { date },
  });
};

export const getUsersByJob = (jobId) => {
  return axios.get(`${API_ENDPOINT()}/users/get-by/job`, {
    params: { jobId },
  });
};

export const getScheduledUsers = (date) => {
  return axios.get(`${API_ENDPOINT()}/users/scheduled`, {
    params: { date },
  });
};

export const getPhoneListByFilter = (filter) => {
  return axios.get(`${API_ENDPOINT()}/users/phone-list/${filter}`);
}

export const getAvailableSchedules = (from, until, userId) => {
  return axios.get(
    `${API_ENDPOINT()}/users/${userId}/availables`,
    {
      params: { from, until },
    }
  );
};

export const getUserJobFilter = (id) => {
  return axios.get(`${API_ENDPOINT()}/users/${id}/job-filter`);
};
