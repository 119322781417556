import moment from "moment";

export default {
  locale: "nl",
  mainFilter: {
    week: moment().startOf("week").add(1, "d").format("YYYY-MM-DD"),
    jobFilterId: null,
    jobId: null,
  },
  schedulerFilter: {
    horecaLocationId: null,
    jobFilterId: null,
  },
  userJobFilter: {},
  templateManagerFilter: {
    horecaLocationId: null,
  },
};
