import { API_ENDPOINT } from "@/utils/misc";
import axios from "axios";

export const get = (from, until, jobFilterId) => {
  return axios.get(`${API_ENDPOINT()}/schedule-dt`, {
    params: { from, until, jobFilterId },
  });
};

export const isAllUserAvailable = (from, until, jobFilterId) => {
  return axios.get(`${API_ENDPOINT()}/schedule-dt/check-users-availibity`, {
    params: { from, until, jobFilterId },
  });
};

export const getPersonnel = (id, jobFilterId, jobId, from, until) => {
  return axios.get(`${API_ENDPOINT()}/schedule-dt/${id}/personnel`, {
    params: { jobFilterId, jobId, from, until },
  });
};

export const getUsersWeekSchedule = (dates, userIds) => {
  return axios.get(`${API_ENDPOINT()}/schedule-dt/get-users-schedule-week`, {
    params: { dates, userIds },
  });
};

export const getAllLastBusByIds = (ids) => {
  return axios.get(`${API_ENDPOINT()}/last-bus-time/get-by-horeca-ids`, {
    params: { ids },
  });
};

export const create = (from, until, jobId) => {
  return axios.post(`${API_ENDPOINT()}/schedule-dt`, {
    from,
    until,
    jobId,
  });
};

export const update = (id, from, until, userId) => {
  return axios.put(`${API_ENDPOINT()}/schedule-dt/${id}`, {
    from,
    until,
    userId,
  });
};

export const remove = (id) => {
  return axios.delete(`${API_ENDPOINT()}/schedule-dt/${id}`);
};

export const publish = (from, until, jobFilterId) => {
  return axios.post(`${API_ENDPOINT()}/schedule-dt/publish`, {
    from,
    until,
    jobFilterId,
  });
};

export const copyFromTemplate = (from, until, templateId, jobFilterId) => {
  return axios.post(`${API_ENDPOINT()}/schedule-dt/copy-from-template`, {
    from,
    until,
    templateId,
    jobFilterId,
  });
};

export const setNewRoosterDayTemplate = (payload) => {
  return axios.post(`${API_ENDPOINT()}/schedule-dt/day-template`, payload);
};
