import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const getAll = () => {
  return axios.get(`${API_ENDPOINT()}/start-times`);
};

export const store = (payload) => {
  return axios.post(`${API_ENDPOINT()}/start-times`, payload);
};

export const destroy = (id) => {
  return axios.delete(`${API_ENDPOINT()}/start-times/delete/${id}`);
};
