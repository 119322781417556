import { getters } from "@/constants/state";

export default {
  [getters.TEMPLATES_GETTER]: (state) => state.templates,
  [getters.TEMPLATES_EDIT_GETTER]: (state) => state.editTemplate,
  [getters.TEMPLATES_SEARCH_GETTER]: (state) => state.searchedTemplates,
  [getters.TEMPLATES_PAGE_DATA_GETTER]: (state) => state.templatesPageData,
  [getters.TEMPLATES_DAY_SHIFT_COUNT_GETTER]: (state) =>
    state.templatesDayShiftCount,
};
