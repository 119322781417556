import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const getUserAvailables = (userId, from, until) => {
  return axios.get(`${API_ENDPOINT()}/availables/getby/user/${userId}`, {
    params: { from, until }
  });
};

export const create = (userId, from, until) => {
  return axios.post(`${API_ENDPOINT()}/availables`, {
    userId,
    from,
    until
  });
};

export const update = (id, from, until) => {
  return axios.put(`${API_ENDPOINT()}/availables/${id}`, {
    from,
    until
  });
};

export const remove = (id) => {
  return axios.delete(`${API_ENDPOINT()}/availables/${id}`);
};
