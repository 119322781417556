import { mutations } from "@/constants/state";
import { reject } from "lodash";

export default {
  [mutations.SCHEDULEDT_FETCH_MUTATION]: (state, payload) => {
    state.schedulesDt = payload;
  },
  [mutations.SCHEDULEDT_REMOVE_MUTATION]: (state, id) => {
    state.schedulesDt = reject(state.schedulesDt, { id });
  },
};
