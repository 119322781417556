import { actions, mutations } from "@/constants/state";
import {
  getNewsList,
  create,
  update,
  remove,
} from "@/services/news-service";

export default {
  [actions.NEWS_FETCH_ACTION]: async (
    { commit },
  ) => {
    const { data } = await getNewsList();
    commit(mutations.NEWS_FETCH_MUTATION, data);
  },
  [actions.NEWS_CREATE_ACTION]: async (
    { commit },
    payload
  ) => {
    const { data } = await create(payload);
    commit(mutations.NEWS_CREATE_MUTATION, data);
  },
  [actions.NEWS_UPDATE_ACTION]: async (
    { commit },
    { payload }
  ) => {
    const { data } = await update(payload.id, payload);
    commit(mutations.NEWS_UPDATE_MUTATION, data);
  },
  [actions.NEWS_REMOVE_ACTION]: async (
    { commit },
    { id }
  ) => {
    await remove(id);
    commit(mutations.NEWS_REMOVE_MUTATION, id);
  },
};
