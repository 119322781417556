import { mutations } from "@/constants/state";

export default {
  [mutations.AVAILABILITY_REMINDER_FETCH_EMPLOYEES_MUTATION]: (
    state,
    payload
  ) => {
    state.employees = payload;
  },
};
