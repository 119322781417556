import { mutations } from "@/constants/state";

export default {
  [mutations.JOBS_FETCH_MUTATION]: (state, payload) => {
    state.jobs = payload;
  },
  [mutations.JOBS_FETCH_USER_JOBS_MUTATION]: (state, payload) => {
    state.userJobs = payload;
  },
  [mutations.JOBS_FETCH_JOB_FILTERS_MUTATION]: (state, payload) => {
    state.jobFilters = payload;
  },
  [mutations.JOBS_FETCH_JOB_FILTER_JOBS_MUTATION]: (state, payload) => {
    state.jobFilterJobs = payload;
  },
};
