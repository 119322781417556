import { API_ENDPOINT } from "@/utils/misc";
import axios from "axios";

export const get = (from, to, jobFilterId = null, userId = null) => {
  return axios.get(`${API_ENDPOINT()}/full-schedules`, {
    params: { from, to, jobFilterId, userId },
  });
};

export const getById = (id) => {
  return axios.get(`${API_ENDPOINT()}/full-schedules/${id}`);
};

export const setDayTemplate = (payload) => {
  return axios.post(`${API_ENDPOINT()}/full-schedules/day-template`, payload);
};

export const create = (start, ending, jobId, locationId) => {
  return axios.post(`${API_ENDPOINT()}/full-schedules`, {
    job_id: jobId,
    horeca_location_id: locationId,
    start,
    ending,
  });
};

export const update = (
  id,
  canceled,
  cancelReasonId,
  addEmployeeSchedule,
  userId,
  start,
  ending,
  jobId,
  locationId
) => {
  return axios.put(`${API_ENDPOINT()}/full-schedules/${id}`, {
    user_id: userId,
    horeca_location_id: locationId,
    cancel_reason_id: cancelReasonId,
    job_id: jobId,
    add_employee_schedule: addEmployeeSchedule,
    canceled: canceled,
    start,
    ending,
  });
};

export const getExtraPersonnel = (from, to, jobFilterId) => {
  return axios.get(`${API_ENDPOINT()}/full-schedules/extra-personnel`, {
    params: { from, to, jobFilterId },
  });
};

export const checkAvailability = (id, userId) => {
  return axios.get(
    `${API_ENDPOINT()}/full-schedules/check-availability/${id}`,
    {
      params: { userId },
    }
  );
};

export const planUser = (id, userId) => {
  return axios.post(`${API_ENDPOINT()}/full-schedules/plan-user/${id}`, {
    userId,
  });
};

export const getUserSchedule = (userId, from, until) => {
  return axios.get(`${API_ENDPOINT()}/full-schedules/getby/user/${userId}`, {
    params: { from, until },
  });
};

export const remove = (id) => {
  return axios.delete(`${API_ENDPOINT()}/full-schedules/${id}`);
};
