export default {
  ADMIN_FULLTIME: [
    {
      url: "/agenda",
      description_key: "agenda_menu_description",
      nav: true,
    },
    {
      url: "/free-day",
      description_key: "freeday_menu_description",
      nav: true,
    },
    {
      url: "/free-day-overview",
      description_key: "freeday_overview_menu_description",
      nav: true,
    },
    {
      url: "/timeout",
      description_key: "timeout_menu_description",
      nav: true,
    },
    {
      url: "/timeout-overview",
      description_key: "timeout_overview_menu_description",
      nav: true,
    },
    {
      url: "/main",
      description_key: "main_menu_description",
      nav: true,
    },
    {
      url: "/rooster",
      description_key: "rooster_menu_description",
      nav: true,
    },
    {
      url: "/new-rooster",
      description_key: "new_rooster_menu_description",
      nav: true,
    },
    {
      url: "/check-rooster",
      description_key: "check_rooster_menu_description",
      nav: true,
    },
    {
      url: "/swap-shifts-overview",
      description_key: "swap_shifts_overview_description",
      nav: true,
    },
    {
      url: "/templates",
      description_key: "template_menu_description",
      nav: true,
    },
    {
      url: "/stats/timeslot",
      description_key: "stats_timeslot_menu_description",
      nav: true,
    },
    {
      url: "/stats/employee",
      description_key: "stats_employee_menu_description",
      nav: false,
    },
    {
      url: "/mail-tool",
      description_key: "mail_tool_menu_description",
      nav: true,
    },
    {
      url: "/phone-list",
      description_key: "phone_list_menu_description",
      nav: true,
    },
    {
      url: "/cancel-reason",
      description_key: "cancel_reason_menu_description",
      nav: true,
    },
    {
      url: "/calloff-overview",
      description_key: "calloff_overview_menu_description",
      nav: true,
    },
    {
      url: "/comment",
      description_key: "comment_menu_description",
      nav: false,
    },
    {
      url: "/notes",
      description_key: "note_menu_description",
      nav: false,
    },
    {
      url: "/special-jobs",
      description_key: "special_job_menu_description",
      nav: false,
    },
    {
      url: "/bonus-jobs",
      description_key: "bonus_job_menu_description",
      nav: false,
    },
    {
      url: "/scheduler",
      description_key: "scheduler_menu_description",
      nav: false,
    },
    {
      url: "/news",
      description_key: "news_description",
      nav: true,
    },
  ],
  ADMIN_PARTTIME: [
    {
      url: "/agenda",
      description_key: "agenda_menu_description",
      nav: true,
    },
    {
      url: "/cancel-reason",
      description_key: "cancel_reason_menu_description",
      nav: false,
    },
    {
      url: "/available",
      description_key: "available_menu_description",
      nav: true,
    },
    {
      url: "/timeout",
      description_key: "timeout_menu_description",
      nav: true,
    },
    {
      url: "/timeout-overview",
      description_key: "timeout_overview_menu_description",
      nav: true,
    },
    {
      url: "/free-day-overview",
      description_key: "freeday_overview_menu_description",
      nav: true,
    },
    {
      url: "/main",
      description_key: "main_menu_description",
      nav: true,
    },
    {
      url: "/rooster",
      description_key: "rooster_menu_description",
      nav: true,
    },
    {
      url: "/new-rooster",
      description_key: "new_rooster_menu_description",
      nav: true,
    },
    {
      url: "/check-rooster",
      description_key: "check_rooster_menu_description",
      nav: true,
    },
    {
      url: "/swap-shifts-overview",
      description_key: "swap_shifts_overview_description",
      nav: true,
    },
    {
      url: "/templates",
      description_key: "template_menu_description",
      nav: true,
    },
    {
      url: "/stats/timeslot",
      description_key: "stats_timeslot_menu_description",
      nav: true,
    },
    {
      url: "/stats/employee",
      description_key: "stats_employee_menu_description",
      nav: false,
    },
    {
      url: "/mail-tool",
      description_key: "mail_tool_menu_description",
      nav: true,
    },
    {
      url: "/phone-list",
      description_key: "phone_list_menu_description",
      nav: true,
    },
    {
      url: "/cancel-reason",
      description_key: "cancel_reason_menu_description",
      nav: true,
    },
    {
      url: "/calloff-overview",
      description_key: "calloff_overview_menu_description",
      nav: true,
    },
    {
      url: "/comment",
      description_key: "comment_menu_description",
      nav: false,
    },
    {
      url: "/history",
      description_key: "history_menu_description",
      nav: true,
    },
    {
      url: "/notes",
      description_key: "note_menu_description",
      nav: false,
    },
    {
      url: "/special-jobs",
      description_key: "special_job_menu_description",
      nav: false,
    },
    {
      url: "/bonus-jobs",
      description_key: "bonus_job_menu_description",
      nav: false,
    },
    {
      url: "/scheduler",
      description_key: "scheduler_menu_description",
      nav: false,
    },
    {
      url: "/news",
      description_key: "news_description",
      nav: true,
    },
  ],
  STAFF_FULLTIME: [
    {
      url: "/free-day",
      description_key: "freeday_menu_description",
      nav: true,
    },
    {
      url: "/timeout",
      description_key: "timeout_menu_description",
      nav: true,
    },
    {
      url: "/main",
      description_key: "staff_main_menu_description",
      nav: true,
    },
    {
      url: "/rooster",
      description_key: "rooster_menu_description",
      nav: true,
    },
    {
      url: "/phone-list",
      description_key: "phone_list_menu_description",
      nav: true,
    },
  ],
  STAFF_PARTTIME: [
    {
      url: "/available",
      description_key: "available_menu_description",
      nav: true,
    },
    {
      url: "/timeout",
      description_key: "timeout_menu_description",
      nav: true,
    },
    {
      url: "/main",
      description_key: "staff_main_menu_description",
      nav: true,
    },
    {
      url: "/rooster",
      description_key: "rooster_menu_description",
      nav: true,
    },
    {
      url: "/phone-list",
      description_key: "phone_list_menu_description",
      nav: true,
    },
  ],
  SUPERUSER: [
    {
      url: "/manager/templates",
      description_key: "manager_templates_description",
      nav: true,
    },
    {
      url: "/manager/start-times",
      description_key: "start_times_description",
      nav: true,
    },
    {
      url: "/manager/scenarios",
      description_key: "manager_scenarios_menu_description",
      nav: true,
    },
    {
      url: "/manager/scenarios/scenario-shifts",
      description_key: "manager_scenarios_scenario_shifts_menu_description",
      nav: false,
    },
    {
      url: "/manager/scheduler",
      description_key: "manager_scheduler_menu_description",
      nav: true,
    },
    {
      url: "/agenda",
      description_key: "agenda_menu_description",
      nav: true,
    },
    {
      url: "/free-day",
      description_key: "freeday_menu_description",
      nav: true,
    },
    {
      url: "/free-day-overview",
      description_key: "freeday_overview_menu_description",
      nav: true,
    },
    {
      url: "/timeout",
      description_key: "timeout_menu_description",
      nav: true,
    },
    {
      url: "/timeout-overview",
      description_key: "timeout_overview_menu_description",
      nav: true,
    },
    {
      url: "/main",
      description_key: "main_menu_description",
      nav: true,
    },
    {
      url: "/rooster",
      description_key: "rooster_menu_description",
      nav: true,
    },
    {
      url: "/new-rooster",
      description_key: "new_rooster_menu_description",
      nav: true,
    },
    {
      url: "/check-rooster",
      description_key: "check_rooster_menu_description",
      nav: true,
    },
    {
      url: "/swap-shifts-overview",
      description_key: "swap_shifts_overview_description",
      nav: true,
    },
    {
      url: "/templates",
      description_key: "template_menu_description",
      nav: true,
    },
    {
      url: "/stats/timeslot",
      description_key: "stats_timeslot_menu_description",
      nav: true,
    },
    {
      url: "/stats/employee",
      description_key: "stats_employee_menu_description",
      nav: false,
    },
    {
      url: "/mail-tool",
      description_key: "mail_tool_menu_description",
      nav: true,
    },
    {
      url: "/phone-list",
      description_key: "phone_list_menu_description",
      nav: true,
    },
    {
      url: "/availability-reminder",
      description_key: "availability_reminder_menu_description",
      nav: true,
    },
    {
      url: "/cancel-reason",
      description_key: "cancel_reason_menu_description",
      nav: true,
    },
    {
      url: "/calloff-overview",
      description_key: "calloff_overview_menu_description",
      nav: true,
    },
    {
      url: "/comment",
      description_key: "comment_menu_description",
      nav: false,
    },
    {
      url: "/notes",
      description_key: "note_menu_description",
      nav: false,
    },
    {
      url: "/special-jobs",
      description_key: "special_job_menu_description",
      nav: false,
    },
    {
      url: "/bonus-jobs",
      description_key: "bonus_job_menu_description",
      nav: false,
    },
    {
      url: "/scheduler",
      description_key: "scheduler_menu_description",
      nav: false,
    },
    {
      url: "/news",
      description_key: "news_description",
      nav: true,
    },
  ],
};
