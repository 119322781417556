<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getters } from "@/constants/state";

export default {
  mounted() {
    this.$i18n.locale = this.locale;
  },
  computed: {
    ...mapGetters({
      locale: getters.SESSION_LOCALE_GETTER,
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lightgray-bg-color {
  background-color: #f8f8f8;
}

.darkgray-bg-color {
  background-color: #dddddd;
}
</style>
