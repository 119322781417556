import { actions, mutations } from "@/constants/state";
import {
  create,
  get,
  getExtraPersonnel,
  getUserSchedule,
  planUser,
  update,
} from "@/services/fullschedule-service";

export default {
  [actions.FULLSCHEDULES_FETCH_ACTION]: async (
    { commit },
    { from, until, jobFilterId = null, userId = null }
  ) => {
    const { data } = await get(from, until, jobFilterId, userId);
    commit(mutations.FULLSCHEDULES_FETCH_MUTATION, data);
  },
  [actions.FULLSCHEDULES_CREATE_ACTION]: async (
    { commit },
    { start, ending, jobId, locationId }
  ) => {
    const { data } = await create(start, ending, jobId, locationId);
    commit(mutations.FULLSCHEDULES_CREATE_MUTATION, data);
  },
  [actions.FULLSCHEDULES_UPDATE_ACTION]: async (
    { commit },
    {
      id,
      canceled,
      cancelReasonId,
      addEmployeeSchedule,
      userId,
      start,
      ending,
      jobId,
      locationId,
    }
  ) => {
    const { data } = await update(
      id,
      canceled,
      cancelReasonId,
      addEmployeeSchedule,
      userId,
      start,
      ending,
      jobId,
      locationId
    );
    commit(mutations.FULLSCHEDULES_UPDATE_MUTATION, data);
  },
  [actions.FULLSCHEDULES_FETCH_EXTRA_PERSONNEL_ACTION]: async (
    { commit },
    { from, until, jobFilterId }
  ) => {
    const { data } = await getExtraPersonnel(from, until, jobFilterId);
    commit(mutations.FULLSCHEDULES_FETCH_EXTRA_PERSONNEL_MUTATION, data);
  },
  [actions.FULLSCHEDULES_PLAN_USER_ACTION]: async (
    { commit },
    { id, userId }
  ) => {
    const { data: user } = await planUser(id, userId);
    commit(mutations.FULLSCHEDULES_PLAN_USER_MUTATION, { id, user });
  },
  [actions.FULLSCHEDULES_FETCH_USER_SCHEDULES_ACTION]: async (
    { commit },
    { userId, from, until }
  ) => {
    const { data } = await getUserSchedule(userId, from, until);
    commit(mutations.FULLSCHEDULES_FETCH_USER_SCHEDULES_MUTATION, data);
  },
};
