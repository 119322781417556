import { actions } from "@/constants/state";
import { get as getReason } from "@/services/cancelreason-service";
import { getById } from "@/services/fullschedule-service";
import { getOptions } from "@/services/user-service";
import store from "@/store";
import * as _ from "lodash";

const getScheduleById = async (to) => {
  if (_.get(to, "meta.update")) {
    const { data } = await getById(_.get(to, "params.id"));
    to.params.fullSchedule = data;
  }
};

const getUsers = async (to) => {
  if (_.get(to, "meta.update")) {
    const { data } = await getOptions(to.params.date);
    to.params.users = data;
  }
};

const getCancelReasonOptions = async (to) => {
  if (_.get(to, "meta.update")) {
    const { data } = await getReason();
    to.params.cancelReasons = data;
  }
};

export default async (to, from, next) => {
  try {
    getCancelReasonOptions(to);
    await Promise.all([
      getScheduleById(to),
      getUsers(to),
      store.dispatch(actions.JOBS_FETCH_ACTION),
    ]);
  } catch (error) {
    console.error("DEBUG: Main - Form - Resolver", error);
  }

  next();
};
