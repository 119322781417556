import { actions } from "@/constants/state";
import store from "@/store";

export default async (to, from, next) => {
  try {
    await store.dispatch(actions.JOBS_FETCH_JOB_FILTERS_ACTION);
  } catch (error) {
    console.error("DEBUG: Main - List - Resolver", error);
  }
  
  next();
};
