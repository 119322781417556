import { actions, getters } from "@/constants/state";
import tailwindSettings from "@/constants/tailwind";
import { create } from "@/services/errorlogs-service";
import moment from "@/utils/moment-utc";
import axios from "axios";
import { get, reverse, sortBy } from "lodash";
import "tailwindcss/tailwind.css";
import Vue from "vue";
import VueTailwind from "vue-tailwind";
import { VuejsDatatableFactory } from "vuejs-datatable";
import "vuejs-datatable/dist/themes/bootstrap-3.esm";
import App from "./App.vue";
import i18n from "./i18n";
import router from "./router";
import store from "./store";

Vue.use(
  VuejsDatatableFactory.registerTableType("datatable", (tableType) => {
    tableType.setSortHandler((data, col, order) => {
      if (!get(col, "sortable")) {
        return data;
      }

      const sorted = sortBy(data, (row) => get(row, col.field, 0));

      if (order === "asc") {
        return sorted;
      }

      return reverse(sorted);
    });
  })
);
Vue.use(VueTailwind, tailwindSettings);
Vue.config.productionTip = false;

axios.interceptors.request.use((config) => {
  const token = store.getters[getters.AUTH_TOKEN_GETTER];

  if (token) {
    config.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (response.status >= 200 && response.status < 300) {
      const excluded = [
        "horeca_locations/check",
        "auth/rehydrate",
        "auth/login",
        "error-logs",
      ];
      const url = get(response, "config.url");
      const lastRehydrate = localStorage.getItem("last-rehydrated");
      let canRehydrate = true;

      if (lastRehydrate) {
        const current = moment.unix(moment.now());
        const last = moment.unix(lastRehydrate);
        const duration = moment.duration(current.diff(last, "seconds"));
        canRehydrate = duration.asSeconds() > 30;
      }

      if (!excluded.some((path) => url.includes(path)) && canRehydrate) {
        try {
          store.dispatch(actions.AUTH_REHYDRATE_ACTION);
        } catch (error) {
          return Promise.reject(response);
        }
      }

      return Promise.resolve(response);
    }

    return Promise.reject(response);
  },
  async (error) => {
    if (!get(error, "response.config.url").includes("error-logs")) {
      try {
        const data = get(error, "response.data");
        const message = typeof data === "string" ? data : get(data, "message");
        const status = get(error, "response.status");
        const url = get(error, "response.config.url");
        await create({
          horeca_location_id: store.getters[getters.DOMAIN_LOCATION_ID_GETTER],
          user_id: store.getters[getters.AUTH_USER_ID_GETTER],
          name: "ADMIN",
          message: `Error Code: ${status}, Message: ${message} ${error.message} ${url}`,
          user_agent: navigator.userAgent,
        });
      } catch (e) {
        console.error(e);
      }
    }

    if (get(error, "response.status")) {
      if ([401, 403].includes(get(error, "response.status"))) {
        store.dispatch(actions.AUTH_LOGOUT_ACTION);
      }

      return Promise.reject(error.response);
    }

    return Promise.resolve(error.response);
  }
);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
