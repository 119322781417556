import { actions } from "@/constants/state";
import store from "@/store";
import moment from "moment";

export default async (to, from, next) => {
  try {
    const now = moment().startOf("week").add(1, "d").format("YYYY-MM-DD");
    const payload = {
      from: now,
      until: moment(now).add(6, "d").format("YYYY-MM-DD")
    };
    await store.dispatch(actions.FREEDAYS_FETCH_OVERVIEW_ACTION, payload);
  } catch (error) {
    console.error(actions.FREEDAYS_FETCH_OVERVIEW_ACTION, error);
  }

  next();
};
