import { mutations } from "@/constants/state";
import { map } from "lodash";

export default {
  [mutations.FULLSCHEDULES_FETCH_MUTATION]: (state, payload) => {
    state.fullSchedules = payload;
  },
  [mutations.FULLSCHEDULES_CREATE_MUTATION]: (state, payload) => {
    state.fullSchedules = [...(state.fullSchedules || []), payload];
  },
  [mutations.FULLSCHEDULES_UPDATE_MUTATION]: (state, payload) => {
    state.fullSchedules = map(state.fullSchedules, (sched) =>
      sched.id === payload.id ? { ...sched, ...payload } : sched
    );
  },
  [mutations.FULLSCHEDULES_FETCH_EXTRA_PERSONNEL_MUTATION]: (
    state,
    payload
  ) => {
    state.extraPersonnel = payload;
  },
  [mutations.FULLSCHEDULES_PLAN_USER_MUTATION]: (
    state,
    payload
  ) => {
    state.fullSchedules = map(state.fullSchedules, (sched) =>
      sched.id === payload.id
        ? { ...sched, user_id: payload.user.id, name: payload.user.name }
        : sched
    );
  },
  [mutations.FULLSCHEDULES_FETCH_USER_SCHEDULES_MUTATION]: (
    state,
    payload
  ) => {
    state.userFullSchedules = payload;
  },
};
