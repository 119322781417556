import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const get = () => {
  return axios.get(`${API_ENDPOINT()}/input-notes`);
};
export const upsert = (text) => {
  return axios.post(`${API_ENDPOINT()}/input-notes/upsert`, {
    text
  });
};
