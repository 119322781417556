import { API_ENDPOINT } from "@/utils/misc";
import axios from "axios";

export const getEvents = (horecaLocationId, jobFilterId, start, end) => {
  return axios.get(`${API_ENDPOINT()}/template-schedules/get-events`, {
    params: { horecaLocationId, jobFilterId, start, end },
  });
};

export const getByDateRange = (payload) => {
  return axios.get(`${API_ENDPOINT()}/template-schedules/get-by/date-range`, {
    params: payload,
  });
};

export const upsert = (payload) => {
  return axios.post(`${API_ENDPOINT()}/template-schedules/upsert`, payload);
};

export const remove = (payload) => {
  return axios.post(`${API_ENDPOINT()}/template-schedules/remove`, payload);
};
