import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const getPendingSwapShifts = () => {
  return axios.get(`${API_ENDPOINT()}/swap-shift/unapproved`);
};

export const getConfirmedSwapShifts = (payload) => {
  return axios.get(`${API_ENDPOINT()}/swap-shift/confirmed`, {
    params: payload,
  });
};

export const updateAction = (id, action) => {
  return axios.post(
    `${API_ENDPOINT()}/swap-shift/set-action/${id}?_method=PUT`,
    { action }
  );
};
