import { actions, mutations } from "@/constants/state";
import { create, get, remove, update } from "@/services/agenda-service";

export default {
  [actions.AGENDAS_FETCH_ACTION]: async ({ commit }) => {
    const { data } = await get();
    commit(mutations.AGENDAS_FETCH_MUTATION, data);
  },
  [actions.AGENDAS_CREATE_ACTION]: async ({ commit }, payload) => {
    const { data } = await create(payload);
    commit(mutations.AGENDAS_CREATE_MUTATION, data);
  },
  [actions.AGENDAS_UPDATE_ACTION]: async ({ commit }, { id, payload }) => {
    const { data } = await update(id, payload);
    commit(mutations.AGENDAS_UPDATE_MUTATION, data);
  },
  [actions.AGENDAS_REMOVE_ACTION]: async ({ commit }, id) => {
    const { data } = await remove(id);
    commit(mutations.AGENDAS_REMOVE_MUTATION, Number(data));
  },
};
