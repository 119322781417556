import { actions, getters } from "@/constants/state";
import store from "@/store";

export default async (to, from, next) => {
  try {
    await store.dispatch(actions.HOLIDAYS_FETCH_USER_HOLIDAYS_ACTION, {
      userId: store.getters[getters.AUTH_USER_ID_GETTER]
    });
  } catch (error) {
    console.error("DEBUG: Timeout - List - Resolver", error);
  }

  next();
};
