import { actions, getters } from "@/constants/state";
import store from "@/store";
import moment from "moment";

export default async (to, from, next) => {
  try {
    const mainFilter = store.getters[getters.SESSION_MAIN_FILTER_GETTER];
    await store.dispatch(actions.FULLSCHEDULES_FETCH_USER_SCHEDULES_ACTION, {
      userId: store.getters[getters.AUTH_USER_ID_GETTER],
      from: mainFilter.week,
      until: moment(mainFilter.week).add(6, "d").format("YYYY-MM-DD")
    })
  } catch (error) {
    console.error("DEBUG: Freeday - List - Resolver", error);
  }

  next();
};
